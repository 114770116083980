<template>
	<modal
		class="modal__block modal__block--middle modal__block--success"
		:name="name"
		:width="550"
		:min-width="550"
		height="auto"
	>
		<div class="modal__block--wrapper">
			<div class="modal__block--close" @click="close">
				<svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M22.5 7.5l-15 15M7.5 7.5l15 15" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
			</div>
			<div class="modal__wrapper">
				<div class="modal__header">
					<div class="modal__title">
						{{ title }}
					</div>
				</div>

				<div class="modal__content">
					<div class="modal__form">
						<div class="block__row">
							<div class="block__row--left">
								<a class="block__item block__item--green" @click.prevent="$emit('accept')">
									<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M10 18.333a8.333 8.333 0 100-16.666 8.333 8.333 0 100 16.666z" stroke="#5ABB5E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M7.5 10l1.667 1.667L12.5 8.333" stroke="#5ABB5E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
									Подписать ЭЦП
								</a>
							</div>
							<div class="block__row--right">
								<a class="block__item block__item--blue" @click="close">
									{{ $t('button.cancel') }}
								</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</modal>
</template>

<script>
export default {
	name: 'ReportManageModal',
	props: {
		title: {
			type: String,
			default: 'Для сохранения журнала, необходимо подтверждение электронно цифровой подписью',
		},
		name: {
			type: String,
			default: 'ReportManageModal',
		},
	},
	emits: ['close', 'accept'],
	methods: {
		close() {
			this.$modal.hide(this.name)
			this.$emit('close')
		},
	},
}
</script>
